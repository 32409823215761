import request from './../request.js'

export function refundrecord(params){
    return request({
        url: 'card/refundrecord/list.do?',
        method: 'post',
        data:params
    })
}

export function refundSync(params){
    return request({
        url: 'card/refundrecord/refundSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}


//failList.vue
export function refundFailList(params){
    return request({
        url: 'card/importcardfail/list.do?',
        method: 'post',
        data:params
    })
}
export function findById(params){
    return request({
        url: 'card/importcardfail/findById.do?',
        method: 'post',
        data:params
    })
}